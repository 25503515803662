import './style.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { Image as ImageLayer } from 'ol/layer';
import { ImageWMS } from 'ol/source';

const osmLayer = new TileLayer({
  source: new OSM()
});

const wmsLayer = new ImageLayer({
  source: new ImageWMS({
    url: 'https://geoportal.mg-test.eu/geoserver/ws_test/wms',
    params: {
      'LAYERS': 'ws_test:frame_lyr',
      'FORMAT': 'image/png',
      'VERSION': '1.1.0'
    }
  })
});

const map = new Map({
  target: 'map',
  layers: [osmLayer, wmsLayer],
  view: new View({
    center: [0, 0],
    zoom: 2
  })
});
